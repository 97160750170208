@tailwind base;
@tailwind components;
@tailwind utilities;

.rows .row {
    display: inline-block;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.h-48 {
    height: 12rem;
}

.w-48 {
    width: 12rem;
}

.w-24 {
    width: 6rem;
}
  
.w-420 {
    width: 25rem;
}

.text-giga {
    font-size: 2rem;
    line-height: 2.5rem;
}

.sti {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}